import React from 'react';
import './App.css';

import NavBar from './../Components/NavBar/NavBar';
import Footer from "./../Components/Sections/Footer";
import {Container} from 'react-bootstrap';


function TermsAndConditions() {

    return (
        <div className="App">
            <div className="navbar-container">
                <NavBar parallax={false}/>
            </div>
            <Container className="terms-and-conditions">
                <h1>Terms and Conditions</h1>
                <p>
                    <span className="section-title">1. Introduction</span>
                    <br/>
                    These Terms and Conditions ("Terms") govern your use of the websites <a
                    href="https://www.opersync.com/">https://www.opersync.com/</a> ("Site") and <a
                    href="https://opersync.cloud/">https://opersync.cloud/</a> ("Site") operated by Sysneting, Inc., a
                    Delaware, USA registered company ("Sysneting", "we", "us", "our" or "us"). By accessing and using
                    the Site, you accept and agree to be bound by these Terms. If you do not agree to these Terms, do
                    not access or use the Site.
                </p>
                <p>
                    <span className="section-title">2. Eligibility</span>
                    <br/>
                    By using the Site, you represent and warrant that you are at least 18 years of age and that you have
                    the legal capacity to accept and comply with these Terms. If you are using the Site on behalf of an
                    entity, you represent and warrant that you have the authority to bind such entity to these Terms.
                </p>
                <p>
                    <span className="section-title">3. Accounts</span>
                    <br/>
                    To access certain features and services on the Site, you may be required to register and create an
                    account. By creating an account, you agree to provide accurate, complete, and current information.
                    It is your responsibility to maintain the confidentiality of your account and password, and you are
                    responsible for all activities that occur under your account.
                </p>
                <p>
                    <span className="section-title">4. Sysneting Services</span>
                    <br/>
                    Sysneting offers OperSync services, which include cloud infrastructure solutions and management and
                    monitoring tools. These services may be subject to additional terms and conditions, which will be
                    provided to you upon contracting for the specific services.
                </p>
                <p>
                    <span className="section-title">5. Cloud Service Providers</span>
                    <br/>
                    Sysneting may use various cloud service providers, such as AWS, Google Cloud, Digital Ocean, and
                    others, to offer its services. By using our services, you agree that Sysneting is not responsible
                    for any failures or interruptions by these providers, and that no compensation will be provided for
                    such interruptions.
                </p>
                <p>
                    <span className="section-title">6. Service Connectivity</span>
                    <br/>
                    Sysneting strives to ensure service connectivity most of the time but does not guarantee 100%
                    availability. We are not responsible for supplier failures that may disrupt service, and we do not
                    offer compensation for such interruptions.
                </p>
                <p>
                    <span className="section-title">7. Proprietary Information</span>
                    <br/>
                    The cloud service provider used by Sysneting, as well as the name of the third-party tools used and
                    any other sensitive information, such as programming languages, databases, and other technical
                    details, are reserved information.
                </p>
                <p>
                    <span className="section-title">8. Security</span>
                    <br/>
                    OperSync provides security tools and configurations to keep the servers made available to its
                    customers secure.
                    servers made available to its customers. However, it is the customer's responsibility
                    to use or not to use these security tools and configurations. Sysneting is not responsible for
                    vulnerabilities that may arise and the customer is responsible for taking all the necessary security
                    measures to protect their information.
                </p>
                <p>
                    <span className="section-title">9. Intellectual Property</span>
                    <br/>
                    All content on the Site, including, but not limited to, text, graphics, logos, icons, images, audio
                    clips, digital downloads, data compilations, and software, is the property of Sysneting or its
                    content suppliers and protected by applicable intellectual property laws. Unauthorized use of the
                    content may violate copyright laws and is prohibited.
                </p>
                <p>
                    <span className="section-title">10. Limited License</span>
                    <br/>
                    Sysneting, Inc grants you a limited, non-exclusive, non-transferable, revocable license to access
                    and use the Site and its services for personal, non-commercial purposes, subject to these Terms. You
                    may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create
                    derivative works from, transfer or sell any information, software, products or services obtained
                    from the Site without Sysneting's prior written consent.
                </p>
                <p>
                    <span className="section-title">11. Links to Third Party Sites</span>
                    <br/>
                    The Site may contain links to third party web sites. These links are provided solely for your
                    convenience and do not imply any endorsement by Sysneting. Sysneting is not responsible for the
                    content of third party websites and makes no representations or warranties regarding the quality,
                    accuracy, reliability, completeness or suitability of such content.
                </p>
                <p>
                    <span className="section-title">12. User-Generated Content</span>
                    <br/>
                    Sysneting, Inc. may allow users to post, upload, submit or transmit content, including but not
                    limited to text, images, videos, links and other materials ("User Content") on the Site. By
                    submitting User Content, you grant Sysneting a worldwide, non-exclusive, transferable,
                    sublicensable, royalty-free, perpetual license to use, copy, modify, distribute, publicly display
                    and create derivative works of such User Content in any medium and through any method of
                    distribution.
                </p>
                <p>
                    <span className="section-title">13. User Conduct</span>
                    <br/>
                    By using the Site, you agree not to: <br/>

                    a. Violate these Terms or any applicable law or regulation; <br/>
                    b. Infringe the intellectual property, privacy or other rights of Sysneting or any third
                    party; <br/>
                    c. Transmit content that is unlawful, harassing, defamatory, obscene, offensive, threatening,
                    abusive, fraudulent, violates another's privacy, contains malicious software or is otherwise
                    inappropriate; c. Transmit content that is unlawful, harassing, defamatory, obscene, offensive,
                    threatening, abusive, fraudulent, violates another's privacy, contains malicious software or is
                    otherwise inappropriate; <br/>
                    d. Interfere with or disrupt the operation of the Site or servers or networks connected to the
                    Site; <br/>
                    e. Use the Site for fraudulent or illegal purposes.
                </p>
                <p>
                    <span className="section-title">14. Cancellation and Termination</span>
                    <br/>
                    Sysneting, Inc. reserves the right to terminate your account and suspend your access to the Site if
                    you violate these Terms, without notice and without refund. In addition, Sysneting may, in its sole
                    discretion, discontinue, modify or discontinue the Site or any part of it at any time without
                    notice.
                </p>
                <p>
                    <span className="section-title">15. No Refund Policy</span>
                    <br/>
                    Sysneting does not provide refunds for services rendered, except as required by applicable law or as
                    otherwise set forth in additional service-specific terms and conditions. By agreeing to these Terms
                    and Conditions, you acknowledge and agree to our no refund policy.
                </p>
                <p>
                    <span className="section-title">16. Customer responsibility for security measures</span>
                    <br/>
                    Although Sysneting offers security tools and configurations to keep the servers made available to
                    its customers secure, it is the customer's responsibility to implement and maintain adequate
                    security measures to protect their information and systems. Sysneting is not responsible for
                    vulnerabilities or security problems that may arise due to the lack of security measures by the
                    customer.
                </p>
                <p>
                    <span className="section-title">17. Limitation of liability</span>
                    <br/>
                    In no event shall Sysneting, its directors, employees, affiliates, agents, contractors or licensors
                    be liable for any direct, indirect, punitive, incidental, special, consequential or exemplary
                    damages arising out of your use of the Site or any information, content or services provided on the
                    Site, even if Sysneting has been advised of the possibility of such damages.
                </p>
                <p>
                    <span className="section-title">18. Indemnification</span>
                    <br/>
                    You agree to indemnify, defend and hold harmless Sysneting, its directors, employees, affiliates,
                    agents, contractors and licensors from and against any claims, liabilities, losses, damages, costs
                    or expenses (including reasonable attorneys' fees) arising out of or related to your use of the
                    Site, your violation of these Terms, your violation of any third party rights, or your illegal or
                    tortious conduct.
                </p>
                <p>
                    <span className="section-title">19. Privacy and Data Protection Policy</span>
                    <br/>
                    Sysneting, Inc. is committed to protecting the privacy of its users. By using the Site, you agree to
                    our Privacy Policy, which is incorporated into these Terms by reference. In addition, Sysneting
                    complies with applicable data protection laws, including the AVEAS Data and the EU General Data
                    Protection Regulation (GDPR).
                </p>
                <p>
                    <span
                        className="section-title">20. Sharing of information between Sysneting's lines of business</span>
                    <br/>
                    Sysneting, Inc. has several lines of business, and information collected through the Site may be
                    shared among these lines of business to improve our services and provide a more personalized
                    experience for our users.
                </p>
                <p>
                    <span className="section-title">21. Modifications to the Terms</span>
                    <br/>
                    Sysneting, Inc. reserves the right to modify these Terms at any time. By continuing to use the Site
                    after changes to the Terms have been made, you agree to such changes. If you do not agree to the
                    changes, you must stop using the Site.
                </p>
                <p>
                    <span className="section-title">22. Severability</span>
                    <br/>
                    If any provision of these Terms shall be deemed invalid, illegal or unenforceable for any reason,
                    such provision shall be deemed severable from these Terms and shall not affect the validity and
                    enforceability of any remaining provisions.
                </p>
                <p>
                    <span className="section-title">23. Waiver</span>
                    <br/>
                    The failure of Sysneting to exercise or enforce any right or provision of these Terms shall not
                    constitute a waiver of such right or provision.
                </p>
                <p>
                    <span className="section-title">24. Governing Law</span>
                    <br/>
                    These Terms shall be governed by and construed in accordance with the laws of the State of Delaware,
                    United States, without regard to its conflicts of law provisions.
                </p>
                <p>
                    <span className="section-title">25. Dispute Resolution</span>
                    <br/>
                    Any dispute, controversy or claim arising out of or relating to these Terms, including their
                    validity, invalidity, breach or termination, shall be resolved by arbitration in accordance with the
                    arbitration rules of the State of Delaware, United States.
                </p>
                <p>
                    <span className="section-title">26. Notices</span>
                    <br/>
                    Notices or communications required or permitted by these Terms shall be in writing and shall be sent
                    by email to the email address provided by you when registering on the Site or to the email address
                    Sysneting has on record for you.
                </p>
                <p>
                    <span className="section-title">27. Force Majeure</span>
                    <br/>
                    Sysneting, Inc. will not be liable for any delay or failure to perform its obligations under these
                    Terms due to causes beyond its reasonable control, including, without limitation, acts of nature,
                    acts of government, floods, fires, earthquakes, civil unrest, acts of terrorism, strikes or other
                    labor unrest, failure of cloud service providers, communication network failures, or any other
                    causes beyond Sysneting's reasonable control.
                </p>
                <p>
                    <span className="section-title">28. Assignment</span>
                    <br/>
                    You may not assign or transfer your rights and obligations under these Terms without Sysneting's
                    prior written consent. Sysneting may assign or transfer its rights and obligations under these Terms
                    to an affiliated entity or in connection with a merger, acquisition, corporate reorganization or
                    sale of all or substantially all of its assets.
                </p>
                <p>
                    <span className="section-title">29. Securities</span>
                    <br/>
                    The headings in these Terms are for reference only and do not affect the interpretation or meaning
                    of the provisions to which they refer.
                </p>
                <p>
                    <span className="section-title">30. Entire Agreement</span>
                    <br/>
                    These Terms, together with any other agreements you may have entered into with Sysneting, constitute
                    the entire agreement between you and Sysneting with respect to the Site and supersede any prior
                    agreements, understandings or communications, whether written or oral.
                </p>
                <p>
                    <span className="section-title">31. Company Proprietary Information</span>
                    <br/>
                    You acknowledge and agree that information about the cloud service providers used by Sysneting, as
                    well as the names of third party tools, programming languages, databases and any other sensitive
                    information related to Sysneting's operation and services, is proprietary and confidential
                    information of the company. You agree not to disclose, share or use such information for
                    unauthorized purposes.
                </p>
                <p>
                    <span className="section-title">32. Connectivity and Service Availability</span>
                    <br/>
                    Sysneting strives to ensure connectivity and service availability most of the time. However,
                    Sysneting is not responsible for service interruptions caused by failures of cloud service providers
                    or other third party providers beyond our control. In addition, service interruptions are not
                    subject to compensation.
                </p>
                <p>
                    <span className="section-title">33. Customer's Responsibility for Information Security</span>
                    <br/>
                    The customer is responsible for taking all necessary security measures to protect its information
                    and systems in the use of the Site and the services provided by Sysneting. Sysneting is not
                    responsible for any vulnerabilities or security problems that may arise due to the lack of security
                    measures by the customer.
                </p>
                <p>
                    <span className="section-title">34. Contact</span>
                    <br/>
                    If you have any questions, comments or concerns regarding these Terms, please contact Sysneting
                    using the contact information provided on the Site.
                </p>
                <p>
                    <span className="section-title">35. Changes to Service</span>
                    <br/>
                    Sysneting reserves the right to make changes to the Site and the services offered at any time
                    without notice. You are responsible for regularly reviewing these Terms and any changes to the
                    services offered.
                </p>
                <p>
                    <span className="section-title">36. Disclaimer of Warranties</span>
                    <br/>
                    The Site and services provided by Sysneting are provided on an "as is" and "as available" basis
                    without warranties of any kind, express or implied, including, but not limited to, implied
                    warranties of merchantability, fitness for a particular purpose and non-infringement. Sysneting does
                    not warrant that the Site or services will be uninterrupted, timely, secure or error-free.
                </p>
                <p>
                    <span className="section-title">37. Restrictions on Use</span>
                    <br/>
                    You agree not to use the Site or Sysneting's services in a manner that violates any law, regulation,
                    court order, third party right or these Terms.
                </p>
                <p>
                    <span className="section-title">38. Cooperation with Legal Authorities</span>
                    <br/>
                    Sysneting will cooperate with legal authorities and comply with any court order, subpoena or other
                    lawful request related to your use of the Site or Sysneting's services, as permitted by applicable
                    law.
                </p>
                <p>
                    <span className="section-title">39. Survival</span>
                    <br/>
                    The provisions of these Terms that, by their nature, should survive termination of these Terms,
                    including, without limitation, provisions relating to intellectual property, indemnification,
                    limitations of liability and dispute resolution, will survive termination of these Terms.
                </p>
                <p>
                    <span className="section-title">40. Copyright Information</span>
                    <br/>
                    Sysneting respects the intellectual property rights of others and expects users of the Site to do
                    the same. If you believe that your work has been copied in a way that constitutes copyright
                    infringement, please send a notice to Sysneting with the information required by the Digital
                    Millennium Copyright Act.
                </p>
                <p>
                    <span className="section-title">41. Use of Trademarks</span>
                    <br/>
                    The trademarks, logos, trade names and service marks (collectively, the "Trademarks") displayed on
                    the Site are the property of Sysneting or their respective owners. You may not use any of these
                    Marks without the prior written consent of the owner of the relevant Mark.
                </p>
                <p>
                    <span className="section-title">42. Links to Third Party Websites</span>
                    <br/>
                    The Site may contain links to third party websites. These links are provided solely for your
                    convenience and do not constitute an endorsement by Sysneting of the content or information provided
                    on those websites. Sysneting has no control over the content of third party websites and assumes no
                    responsibility for the accuracy, legality or any other aspect relating to such websites.

                </p>
                <p>
                    <span className="section-title">43. Geographic Restrictions</span>
                    <br/>
                    Access to and use of the Site and Sysneting's services may be subject to geographical restrictions.
                    Sysneting makes no representation that the Site or services are available or appropriate for use in
                    all countries or jurisdictions. You are responsible for complying with all applicable laws in your
                    jurisdiction regarding access to and use of the Site and Sysneting's services.
                </p>
                <p>
                    <span className="section-title">44. Termination</span>
                    <br/>
                    Sysneting, Inc. reserves the right to suspend or terminate your access to the Site and Sysneting
                    services at any time and for any reason, including for breach of these Terms. In the event of
                    termination, Sysneting will not be obligated to provide you with any refund.
                </p>
                <p>
                    <span className="section-title">45. Cancellation Policy</span>
                    <br/>
                    You may cancel your account or subscription to Sysneting's services at any time by following the
                    instructions provided on the Site. Sysneting does not offer refunds for cancellations.
                </p>
                <p>
                    <span className="section-title">46. Suspension for Non-Compliance</span>
                    <br/>
                    Sysneting, Inc. reserves the right to suspend or terminate your access to the Site and Sysneting's
                    services in the event of any breach of these Terms or any other applicable Sysneting policy. In the
                    event of suspension or termination for breach, Sysneting will not be obligated to provide you with
                    any refund.
                </p>
                <p>
                    <span className="section-title">47. Response Times</span>
                    <br/>
                    Sysneting strives to provide fast and efficient response times for inquiries and requests for
                    assistance related to the Site and Sysneting's services. However, Sysneting does not guarantee
                    specific response times and shall not be responsible for any delay in responding to such inquiries
                    or requests.
                </p>
                <p>
                    <span className="section-title">48. Language</span>
                    <br/>
                    These Terms are written in English. Any translation of these Terms into another language is for your
                    convenience only and should not be considered an official interpretation of these Terms. In the
                    event of any discrepancy between the English version and any translation, the English version shall
                    prevail.
                </p>
                <p>
                    <span className="section-title">49. Electronic Counterparts and Electronic Signatures</span>
                    <br/>
                    You agree that these Terms and any related documents may be signed electronically and that
                    electronic signatures on these Terms and such documents are legally binding.
                </p>
                <p>
                    <span className="section-title">50. Export</span>
                    <br/>
                    You agree to comply with all applicable laws and regulations relating to the export, re-export,
                    transfer and use of Sysneting's services, including but not limited to U.S. export control
                    regulations.
                </p>
                <p>
                    <span className="section-title">51. End User License Agreement</span>
                    <br/>
                    By using the Site and Sysneting's services, you agree that any software provided by Sysneting will
                    be subject to the terms and conditions of a separate end user license agreement ("EULA") that may be
                    provided with the software. You agree to comply with the terms and conditions of such EULA.
                </p>
                <p>
                    <span className="section-title">52. Privacy Policy</span>
                    <br/>
                    Sysneting values your privacy and is committed to protecting the personal information you share with
                    us. Please refer to our Privacy Policy, available on the Site, for information on how Sysneting
                    collects, uses and protects your personal information.
                </p>
                <p>
                    <span className="section-title">53. Cookie Policy</span>
                    <br/>
                    Sysneting may use cookies and other similar technologies to enhance and personalize your experience
                    on the Site. Please see our Cookie Policy, available on the Site, for information on how Sysneting
                    uses cookies and how you can manage your cookie preferences.

                </p>
                <p>
                    <span className="section-title">54. Acceptable Use Policy</span>
                    <br/>
                    You agree to comply with our Acceptable Use Policy, which sets forth guidelines and restrictions
                    regarding your use of the Site and Sysneting's services. The Acceptable Use Policy is located on the
                    Site and is incorporated herein by reference.
                </p>
                <p>
                    <span className="section-title">55. No Refund Policy</span>
                    <br/>
                    Sysneting does not provide refunds in the event of termination of your account or subscription to
                    Sysneting's services, or in the event of suspension or termination of your access to the Site and
                    Sysneting's services due to a breach of these Terms or other applicable Sysneting policy.
                </p>
                <p>
                    <span className="section-title">56. Other Terms and Conditions</span>
                    <br/>
                    Certain services or features of the Site may be subject to additional terms and conditions specific
                    to those services or features. You agree to comply with such additional terms and conditions and
                    acknowledge that failure to comply with such terms and conditions may result in the suspension or
                    termination of your access to the Site and Sysneting's services.
                </p>


            </Container>
            <Footer/>
        </div>
    );
}

export default TermsAndConditions;
