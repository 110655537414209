import React from 'react';
import {Card} from 'react-bootstrap';
import './Security.css';
import img_card4 from './../../src/img/img-card4.svg';

const Security = () => {
    return (
        <Card className="card-security">
            <div className="image-wrapper">
                <Card.Img
                    src={img_card4} alt="Image"/>
            </div>
            <Card.Body className="text-body">
                <Card.Title>Security</Card.Title>
                <Card.Text>
                        For us security is not a game, that's why we take it
                        very seriously and that's why we implement all security measures so that your information
                        and your servers are always safe, we help you to have your site always with an excellent
                        security rating to help you with your PCI certification if required, your access to the
                        platform will always be unique, we always invite you to follow all our security
                        recommendations.
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default Security;
