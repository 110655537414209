// eslint-disable-next-line
import React, { useEffect } from 'react';

const MetaTags = ({
                      title = '',
                      description = '',
                      keywords = '',
                      author = '',
                      ogTitle = '',
                      ogDescription = '',
                      ogImage = '',
                      favicon = '',
                  }) => {
    useEffect(() => {
        // Actualizar título de la página
        document.title = title;

        // Actualizar etiquetas meta
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', description);
        }

        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
            metaKeywords.setAttribute('content', keywords);
        }

        const metaAuthor = document.querySelector('meta[name="author"]');
        if (metaAuthor) {
            metaAuthor.setAttribute('content', author);
        }

        const metaOGTitle = document.querySelector('meta[property="og:title"]');
        if (metaOGTitle) {
            metaOGTitle.setAttribute('content', ogTitle);
        }

        const metaOGDescription = document.querySelector('meta[property="og:description"]');
        if (metaOGDescription) {
            metaOGDescription.setAttribute('content', ogDescription);
        }

        const metaOGImage = document.querySelector('meta[property="og:image"]');
        if (metaOGImage) {
            metaOGImage.setAttribute('content', ogImage);
        }

        // Actualizar favicon
        const link = document.querySelector('link[rel="icon"]');
        if (link) {
            link.setAttribute('href', favicon);
            link.setAttribute('type', 'image/png');
        }
    }, [title, description, keywords, author, ogTitle, ogDescription, ogImage, favicon]);

    return null;
};

export default MetaTags;
