import React from 'react';
import NavBar from '../Components/NavBar/NavBar';
import Footer from '../Components/Sections/Footer';
import {LazyLoadImage} from "react-lazy-load-image-component";
import notFoundIcon from '../src/img/404.svg';
import './OrderSuccess.css';

const NotFound = () => {
    return (
        <div className="order-success-container">
            <NavBar parallax={false} />
            <div className="order-success-content">
                <div className="order-success-icon">
                    <LazyLoadImage
                        src={notFoundIcon}
                        className="img-lazy"
                        alt="NotFound"
                        effect='blur' // opacity | black-and-white
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NotFound;
