import React from 'react';
import './App.css';

import TopBar from './../Components/TopBar/TopBar';
import NavBar from './../Components/NavBar/NavBar';
import SectionHome from "./../Components/Sections/SectionHome";
import Newsletter from "./../Components/Sections/Newsletter";
import Features from "./../Components/Sections/Features";
import Footer from "./../Components/Sections/Footer";
import Pricing from "./../Components/Sections/Pricing";
import Contact from "./../Components/Sections/Contact";
import {Container} from 'react-bootstrap';


function Home() {

    return (
        <div className="App">
            <div className="navbar-container">
                <TopBar/>
                <NavBar/>
            </div>
            <Container>
                <SectionHome/>
                <Newsletter/>
                <Features/>
                <Pricing/>
                <Contact/>
            </Container>
            <Footer/>
        </div>
    );
}

export default Home;
