import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import CheckoutButton from "../../stripe/CheckoutButton";
import './css/Pricing.css';
import icon_check_list from './../../src/img/icon-check-list.svg';

const PricingVpsHosting = () => {
    const [selectedTab, setSelectedTab] = useState('month');
    const [showModal, setShowModal] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [email, setEmail] = useState('');
    const [productId, setProductId] = useState('');
    const [productIdSuscription, setProductIdSuscription] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [price, setPrice] = useState('');
    const [priceSubscription, setPriceSubscription] = useState('');
    const [subscriptionChecked, setSubscriptionChecked] = useState(false);
    const [modalPrice, setModalPrice] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
    };

    const handlePreOrder = (productId, productIdSuscription, product, price, priceSubscription) => {
        setProductId(productId);
        setProductIdSuscription(productIdSuscription);
        setSelectedProduct(product);
        setPrice(price);
        setPriceSubscription(priceSubscription);
        setModalPrice(price);
        setShowModal(true);
    };

    const toggleSubscription = () => {
        setSubscriptionChecked(!subscriptionChecked);
        if (subscriptionChecked) {
            setModalPrice(price);
            setProductId(productId);
        } else {
            setModalPrice(priceSubscription);
            setProductId(productIdSuscription);
        }
    };

    const testMode = process.env.REACT_APP_TEST_MOD;
    const pricingDataProd = [
        {
            title: 'Basic',
            prices: [
                {
                    id: 'price_1NCf4kEs2WLYf5qGxqtg1ioj',
                    idSuscription: 'price_1NCf4kEs2WLYf5qGkV5mVfx6',
                    type: 'month',
                    name: 'VPS Hosting - Basic / Month',
                    shortDescription: 'CI/CD, 2 CPU cores, 2 GB RAM, 40 GB SSD RAID 10, 1000 GB bandwidthCheck, Up to 3 team members',
                    price: 15.32,
                    originalPrice: 30.63,
                    priceSubscription: 13.78,
                    class: '',
                    description: "Ideal for those projects that you are just testing but require significant resources.",
                    features: ['2 CPU cores', '2 GB RAM', '40 GB SSD', '1000 GB bandwidth', 'Up to 3 team members']
                },
                {
                    id: 'price_1NCf4kEs2WLYf5qGruqg1NDZ',
                    idSuscription: 'price_1NCf4kEs2WLYf5qGruqg1NDZ',
                    type: 'year',
                    name: 'VPS Hosting - Basic / Year',
                    price: 183.84,
                    originalPrice: 367.56,
                    priceSubscription: 165.36,
                    class: '',
                    description: 'Ideal for those projects that you are just testing but require significant resources.',
                    features: ['2 CPU cores', '2 GB RAM', '40 GB SSD', '1000 GB bandwidth', 'Up to 3 team members']
                }
            ]
        },
        {
            title: 'Pro',
            prices: [
                {
                    id: 'price_1NCf06Es2WLYf5qGx4TipX9D',
                    idSuscription: 'price_1NCf06Es2WLYf5qGlUQ6wprh',
                    type: 'month',
                    price: 109.60,
                    originalPrice: 156,
                    priceSubscription: 98.28,
                    name: 'VPS Hosting - Pro / Month',
                    class: 'active',
                    description: "You need much more power, don't worry, this plan is the upgrade you need.",
                    features: ['4 CPU cores', '6 GB RAM', '120 GB SSD', '3000 GB bandwidth', 'Up to 5 team members']
                },
                {
                    id: 'price_1NCf06Es2WLYf5qGg941RxHn',
                    idSuscription: 'price_1NCf06Es2WLYf5qGg941RxHn',
                    type: 'year',
                    price: 1315.20,
                    originalPrice: 1872,
                    priceSubscription: 1179.36,
                    name: 'VPS Hosting - Pro / Year',
                    class: 'active',
                    description: "You need much more power, don't worry, this plan is the upgrade you need.",
                    features: ['4 CPU cores', '6 GB RAM', '120 GB SSD', '3000 GB bandwidth', 'Up to 5 team members']
                }
            ]
        },
        {
            title: 'Enterprise',
            prices: [
                {
                    id: 'price_1NCf9HEs2WLYf5qG7R0sYfVM',
                    idSuscription: 'price_1NCf9HEs2WLYf5qGEITnu5Vs',
                    type: 'month',
                    price: 210,
                    originalPrice: 300,
                    priceSubscription: 189,
                    name: 'VPS Hosting - Enterprise / Month',
                    class: '',
                    description: 'If you have a solid product that is constantly growing this plan can help you, if you feel that it does not meet your needs, do not hesitate to contact us to provide you with a better solution.',
                    features: ['8 CPU cores', '12 GB RAM', '240 GB SSD', '6000 GB bandwidth', 'Up to 10 team members']
                },
                {
                    id: 'price_1NCf9HEs2WLYf5qGkFYtzECF',
                    idSuscription: 'price_1NCf9HEs2WLYf5qGkFYtzECF',
                    type: 'year',
                    price: 2520,
                    originalPrice: 3600,
                    priceSubscription: 2268,
                    name: 'VPS Hosting - Enterprise / Year',
                    class: '',
                    description: 'If you have a solid product that is constantly growing this plan can help you, if you feel that it does not meet your needs, do not hesitate to contact us to provide you with a better solution.',
                    features: ['8 CPU cores', '12 GB RAM', '240 GB SSD', '6000 GB bandwidth', 'Up to 10 team members']
                }
            ]
        }
    ];

    const pricingDataStg = [
        {
            title: 'Basic',
            prices: [
                {
                    id: 'price_1NCf4kEs2WLYf5qGxqtg1ioj',
                    idSuscription: 'price_1NCf4kEs2WLYf5qGkV5mVfx6',
                    type: 'month',
                    name: 'VPS Hosting - Basic / Month',
                    shortDescription: 'CI/CD, 2 CPU cores, 2 GB RAM, 40 GB SSD RAID 10, 1000 GB bandwidthCheck, Up to 3 team members',
                    price: 15.32,
                    originalPrice: 30.63,
                    priceSubscription: 13.78,
                    class: '',
                    description: "Ideal for those projects that you are just testing but require significant resources.",
                    features: ['2 CPU cores', '2 GB RAM', '40 GB SSD', '1000 GB bandwidth', 'Up to 3 team members']
                },
                {
                    id: 'price_1NCf4kEs2WLYf5qGruqg1NDZ',
                    idSuscription: 'price_1NCf4kEs2WLYf5qGruqg1NDZ',
                    type: 'year',
                    name: 'VPS Hosting - Basic / Year',
                    price: 183.84,
                    originalPrice: 367.56,
                    priceSubscription: 165.36,
                    class: '',
                    description: 'Ideal for those projects that you are just testing but require significant resources.',
                    features: ['2 CPU cores', '2 GB RAM', '40 GB SSD', '1000 GB bandwidth', 'Up to 3 team members']
                }
            ]
        },
        {
            title: 'Pro',
            prices: [
                {
                    id: 'price_1NCf06Es2WLYf5qGx4TipX9D',
                    idSuscription: 'price_1NCf06Es2WLYf5qGlUQ6wprh',
                    type: 'month',
                    price: 109.60,
                    originalPrice: 156,
                    priceSubscription: 98.28,
                    name: 'VPS Hosting - Pro / Month',
                    class: 'active',
                    description: "You need much more power, don't worry, this plan is the upgrade you need.",
                    features: ['4 CPU cores', '6 GB RAM', '120 GB SSD', '3000 GB bandwidth', 'Up to 5 team members']
                },
                {
                    id: 'price_1NCf06Es2WLYf5qGg941RxHn',
                    idSuscription: 'price_1NCf06Es2WLYf5qGg941RxHn',
                    type: 'year',
                    price: 1315.20,
                    originalPrice: 1872,
                    priceSubscription: 1179.36,
                    name: 'VPS Hosting - Pro / Year',
                    class: 'active',
                    description: "You need much more power, don't worry, this plan is the upgrade you need.",
                    features: ['4 CPU cores', '6 GB RAM', '120 GB SSD', '3000 GB bandwidth', 'Up to 5 team members']
                }
            ]
        },
        {
            title: 'Enterprise',
            prices: [
                {
                    id: 'price_1NCf9HEs2WLYf5qG7R0sYfVM',
                    idSuscription: 'price_1NCf9HEs2WLYf5qGEITnu5Vs',
                    type: 'month',
                    price: 210,
                    originalPrice: 300,
                    priceSubscription: 189,
                    name: 'VPS Hosting - Enterprise / Month',
                    class: '',
                    description: 'If you have a solid product that is constantly growing this plan can help you, if you feel that it does not meet your needs, do not hesitate to contact us to provide you with a better solution.',
                    features: ['8 CPU cores', '12 GB RAM', '240 GB SSD', '6000 GB bandwidth', 'Up to 10 team members']
                },
                {
                    id: 'price_1NCf9HEs2WLYf5qGkFYtzECF',
                    idSuscription: 'price_1NCf9HEs2WLYf5qGkFYtzECF',
                    type: 'year',
                    price: 2520,
                    originalPrice: 3600,
                    priceSubscription: 2268,
                    name: 'VPS Hosting - Enterprise / Year',
                    class: '',
                    description: 'If you have a solid product that is constantly growing this plan can help you, if you feel that it does not meet your needs, do not hesitate to contact us to provide you with a better solution.',
                    features: ['8 CPU cores', '12 GB RAM', '240 GB SSD', '6000 GB bandwidth', 'Up to 10 team members']
                }
            ]
        }
    ];

    const pricingData = (testMode === "true") ? pricingDataStg : pricingDataProd;

    const getPricesByType = (prices, type) => {
        return prices.find((price) => price.type === type);
    };

    const isValidEmail = (email) => {
        // Basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isOrderValid = () => {
        return isValidEmail(email) && quantity > 0 && termsAccepted;
    };

    return (
        <div>
            <Tabs activeKey={selectedTab} onSelect={handleTabChange} className="tab-container justify-content-center">
                <Tab eventKey="month" title="Monthly">
                    <Row className="pricing-row">
                        {pricingData.map((data, index) => {
                            const prices = getPricesByType(data.prices, 'month');
                            return (
                                prices && (
                                    <Col lg={4} mb={5} mb-lg={0} key={index}>
                                        <Card className={`bg-white p-5 rounded-lg shadow ${prices.class}`}>
                                            <h1 className="h6 text-uppercase font-weight-bold mb-4">{data.title}</h1>
                                            <h2 className="h1 font-weight-bold">
                                                ${prices.price} <span className="text-small font-weight-normal ml-2">/ month</span>
                                            </h2>
                                            {prices.originalPrice > prices.price && (
                                                <h2>
                                                    <span className="price-regular-text">Regular Price</span>{' '}
                                                    <span className="text-small font-weight-normal ml-2 price-before">
                            ${prices.originalPrice} / month
                          </span>
                                                </h2>
                                            )}
                                            <div className="custom-separator my-4 mx-auto"></div>
                                            <span>{prices.description}</span>
                                            <hr />
                                            <ul className="list-unstyled my-4 text-small text-left">
                                                {prices.features.map((feature, index) => (
                                                    <li key={index} className="mb-3">
                                                        <img src={icon_check_list} alt="" /> {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                            <Button
                                                variant="primary-opersync"
                                                className="btn-block p-2 shadow rounded-pill"
                                                onClick={() =>
                                                    handlePreOrder(
                                                        prices.id,
                                                        prices.idSuscription,
                                                        prices.name,
                                                        prices.price,
                                                        prices.priceSubscription
                                                    )
                                                }
                                            >
                                                Pre Order
                                            </Button>
                                        </Card>
                                    </Col>
                                )
                            );
                        })}
                    </Row>
                </Tab>
                <Tab eventKey="year" title="Yearly">
                    <Row className="pricing-row">
                        {pricingData.map((data, index) => {
                            const prices = getPricesByType(data.prices, 'year');
                            return (
                                prices && (
                                    <Col lg={4} mb={5} mb-lg={0} key={index}>
                                        <Card className={`bg-white p-5 rounded-lg shadow ${prices.class}`}>
                                            <h1 className="h6 text-uppercase font-weight-bold mb-4">{data.title}</h1>
                                            <h2 className="h1 font-weight-bold">
                                                ${prices.price}
                                                <span className="text-small font-weight-normal ml-2">/ year</span>
                                            </h2>
                                            {prices.originalPrice > prices.price && (
                                                <h2>
                                                    <span className="price-regular-text">Regular Price</span>{' '}
                                                    <span className="text-small font-weight-normal ml-2 price-before">
                            ${prices.originalPrice} / month
                          </span>
                                                </h2>
                                            )}
                                            <div className="custom-separator my-4 mx-auto"></div>
                                            <span>{prices.description}</span>
                                            <hr />
                                            <ul className="list-unstyled my-4 text-small text-left">
                                                {prices.features.map((feature, index) => (
                                                    <li key={index} className="mb-3">
                                                        <img src={icon_check_list} alt="" /> {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                            <Button
                                                variant="primary-opersync"
                                                className="btn-block p-2 shadow rounded-pill"
                                                onClick={() =>
                                                    handlePreOrder(
                                                        prices.id,
                                                        prices.idSuscription,
                                                        prices.name,
                                                        prices.price,
                                                        prices.priceSubscription
                                                    )
                                                }
                                            >
                                                Pre Order
                                            </Button>
                                        </Card>
                                    </Col>
                                )
                            );
                        })}
                    </Row>
                </Tab>
            </Tabs>

            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Place an Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <h5>Order Summary</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9}>
                            <Form.Group controlId="email">
                                <Form.Label>
                                    E-mail <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group controlId="quantity">
                                <Form.Label>
                                    Quantity <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    min={1}
                                    value={quantity}
                                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {priceSubscription > 0 && (
                        <Row>
                            <Col md={12}>
                                <br />
                                <Form.Group controlId="subscription">
                                    <Form.Check
                                        type="checkbox"
                                        label="I would like a monthly subscription (save 10%)"
                                        className="text-suscription"
                                        checked={subscriptionChecked}
                                        onChange={toggleSubscription}
                                        id="subscription-checkbox"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col md={12}>
                            <hr />
                            <Row>
                                <Col md={12}>
                                    <span className="fw-bold">Product:</span>
                                    <br />
                                    {selectedProduct}
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}></Col>
                                <Col md={6} className="text-right">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <p className="text-muted mb-0">
                                            <span className="fw-bold me-4">Total:</span>
                                        </p>
                                        <p className="text-muted mb-0">${modalPrice * quantity}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <hr />
                            <Form.Group controlId="terms">
                                <Form.Check
                                    type="checkbox"
                                    label={
                                        <>
                                            I accept the{' '}
                                            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                terms and conditions
                                            </a>
                                            <span className="text-danger">*</span>
                                        </>
                                    }
                                    checked={termsAccepted}
                                    onChange={() => setTermsAccepted(!termsAccepted)}
                                    id="terms-checkbox"
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    {isOrderValid() ? (
                        <CheckoutButton email={email} qty={quantity} product_id={productId} suscription={subscriptionChecked} />
                    ) : (
                        <Button variant="primary-opersync" disabled>
                            Pre Order
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PricingVpsHosting;
