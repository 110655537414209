import React from 'react';
import {Col, Row} from 'react-bootstrap';
import './css/SectionHome.css';

const SectionHome = () => {
    return (
        <div id="home-section">
            <Row className="SectionHome">
                <Col md={6} className="SectionHome-Col-1">
                    <h1 className="heading-h1-size">
                        Simplify your cloud deployment processes
                    </h1>
                    <p className="paragraph-default">
                        OperSync is a cloud services platform that helps simplify your cloud application deployment
                        processes, optimizing time and helping development teams to focus on product quality. Additionally,
                        we provide a git version control framework that facilitates code review as well as continuous
                        integration implementation.
                    </p>
                </Col>
                <Col md={6}>
                    <div style={{ padding: '75% 0 0 0', position: 'relative' }}>
                        <iframe
                            src="https://player.vimeo.com/video/832085698?h=1f7f9a5db6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            title="Opersync"
                        ></iframe>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SectionHome;
