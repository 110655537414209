import React from 'react';
import {Card} from 'react-bootstrap';
import './MultipleUsers.css';
import img_card3 from './../../src/img/img-card3.svg';

const MultipleUsers = () => {
    return (
        <Card className="card-multi-users">
            <div className="image-wrapper">
                <Card.Img
                    src={img_card3} alt="Image"/>
            </div>
            <Card.Body className="text-body">
                <Card.Title>Multiple users</Card.Title>
                <Card.Text>
                    Efficiently manage your equipment, establishing the
                    necessary permissions to be able to work in a more structured way, giving access only to
                    authorized personnel, in this way, your ambitions will be more effective.
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default MultipleUsers;
