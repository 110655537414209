import React from 'react';
import NavBar from '../Components/NavBar/NavBar';
import Footer from '../Components/Sections/Footer';
import {LazyLoadImage} from "react-lazy-load-image-component";
import errorIcon from '../src/img/error.svg';
import './OrderSuccess.css';

const Error = () => {
    return (
        <div className="order-success-container">
            <NavBar parallax={false}/>
            <div className="order-success-content">
                <div className="order-success-icon">
                    <LazyLoadImage
                        src={errorIcon}
                        className="img-lazy"
                        alt="Error"
                        effect='blur' // opacity | black-and-white
                    />
                </div>
                <h2 className="order-success-heading">Payment Error</h2>
                <p className="order-success-text">We encountered an error while processing your payment.</p>
                <hr className="order-hr"/>
                <p className="order-success-text">Please try again later or contact customer support for assistance.</p>
            </div>
            <Footer/>
        </div>
    );
};

export default Error;
