import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MetaTags from "./Components/MetaTags/MetaTags";
import Home from "./Pages/Home";
import TermsAndConditions from "./Pages/TermsAndConditions";
import OrderSuccess from "./Pages/OrderSuccess";
import Error from "./Pages/Error";
import NotFound from "./Pages/NotFound";
import favicon from "./src/img/favicon.png";

function App() {

    return (
        <div>
            <MetaTags
                favicon={favicon}
                title="OperSync By. Sysneting, Inc. "
                description="OperSync is a comprehensive cloud project management and server administration tool. Streamline your development process with continuous integration and deployment, automated code validation, and a cloud repository. Achieve scalable and efficient deployments with our CI/CD pipeline. Collaborate seamlessly with your team and monitor deployments with ease. Experience efficient project management and streamlined DevOps practices with OperSync."
                keywords="Cloud project management, Server management, Continuous integration and deployment, CI/CD pipeline, Code validation, Cloud repository, Deployment automation, DevOps tool, Infrastructure management, Cloud infrastructure, Scalable deployments, Version control system, Automated testing, Agile project management, Collaborative development, Team collaboration, DevOps platform, Deployment monitoring, Release management, Deployment validation, Gestión de proyectos en la nube, Administración de servidores, Integración y despliegue continuo, Pipeline de CI/CD, Validación de código, Repositorio en la nube, Automatización de despliegues, Herramienta de DevOps, Gestión de infraestructura, Infraestructura en la nube, Despliegues escalables, Sistema de control de versiones, Pruebas automatizadas, Gestión ágil de proyectos, Desarrollo colaborativo, Colaboración de equipos, Plataforma de DevOps, Monitoreo de despliegues, Gestión de versiones, Validación de despliegues"
                author="Sysneting, Inc."
                ogTitle="OperSync By. Sysneting, Inc. "
                ogDescription="OperSync is a comprehensive cloud project management and server administration tool. Streamline your development process with continuous integration and deployment, automated code validation, and a cloud repository. Achieve scalable and efficient deployments with our CI/CD pipeline. Collaborate seamlessly with your team and monitor deployments with ease. Experience efficient project management and streamlined DevOps practices with OperSync."
                ogImage="URL de la imagen para Open Graph"
            />
            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route path="/success" element={<OrderSuccess />} />
                    <Route path="/error" element={<Error />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
