import React from 'react';
import {Card} from 'react-bootstrap';
import './VersionControl.css';
import img_card2 from './../../src/img/img-card2.svg';

const VersionControl = () => {
    return (
        <Card className="card-version-control">
            <div className="image-wrapper">
                <Card.Img
                    src={img_card2} alt="Image"/>
            </div>
            <Card.Body className="text-body">
                <Card.Title>Version Control</Card.Title>
                <Card.Text>
                        Rollback has never been so easy, with the
                        implementation of version control, we have removed the trauma that sometimes rollback can
                        cause, it generates much more organized rollbacks to production and with the necessary
                        backups, in case your version fails in production, you can simply rollback with a click, so
                        you can restore to your stable version and check what went wrong.
                        <br/>
                        Review deployed changes and perform environment synchronizations in a very easy way.
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default VersionControl;
