import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PricingVpsHosting from "./PricingVpsHosting";
import PricingDedicatedServers from "./PricingDedicatedServers";
import './css/Pricing.css';
const Pricing = () => {
    return (
        <div id="pricing-section">
            <Row className="Pricing">
                <Col md={12} className="Pricing-Col-1">
                    <h1 className="heading-h1-size">
                        Affordable pricing plans
                    </h1>
                    <p className="paragraph-default">
                        We have a large number of plans that it is difficult to put them all in one view, but we show
                        you the 3 most popular options, if you are looking for something different from these options,
                        please do not hesitate to contact us to give you the plan that best suits your needs.
                    </p>
                </Col>
            </Row>
            <Row className="Pricing">
                <Col md={12} className="Pricing-Col-1">
                    <h1 className="heading-h1-size">
                        VPS Hosting Plans
                    </h1>
                </Col>
            </Row>
            <PricingVpsHosting/>
            <Row className="Pricing">
                <Col md={12} className="Pricing-Col-1">
                    <h1 className="heading-h1-size">
                        Dedicated Server Plans
                    </h1>
                </Col>
            </Row>
            <PricingDedicatedServers/>
        </div>
    );
};

export default Pricing;
