import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {LazyLoadImage} from "react-lazy-load-image-component";
import logo from './../../src/img/logo.png';
import './css/Footer.css';

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            const documentHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

            if (scrollTop + windowHeight >= documentHeight) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <footer className={`footer bg border-top ${isVisible ? 'visible' : ''}`}>
            <Container>
                <div className="footer-content">
                    <div className="logo">
                        <LazyLoadImage
                            src={logo}
                            className="img-lazy d-inline-block align-top" height={30}
                            effect='blur' // opacity | black-and-white
                        />
                    </div>

                    <p className="copyrigth">
                        <sub>
                            Copyright © 2023
                            <a href="https://www.sysneting.com"> Sysneting, Inc.</a>
                        </sub>
                        <sub> | All Rights Reserved</sub>
                    </p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
