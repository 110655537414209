import React from 'react';
import {Card} from 'react-bootstrap';
import './CI_CD.css';
import img_ci_cd from './../../src/img/img-card1.svg';

const CI_CD = () => {
    return (
        <Card className="card-ci-cd">
            <div className="card-content">
                <div className="text-wrapper">
                    <Card.Body className="text-body">
                        <Card.Title>CI/CD</Card.Title>
                        <Card.Text>
                            If your team does not have experience in CI/CD, do not worry, we make that tedious work
                            easier, each project incorporates its own CI/CD so that you have a layer of validation on
                            what is deployed to your work clients.
                        </Card.Text>
                    </Card.Body>
                </div>
                <div className="image-wrapper">
                    <Card.Img
                        src={img_ci_cd}
                        alt="Imagen de la tarjeta"/>
                </div>
            </div>
        </Card>
    );
};

export default CI_CD;
