import React from 'react';
import NavBar from '../Components/NavBar/NavBar';
import Footer from '../Components/Sections/Footer';
import {LazyLoadImage} from "react-lazy-load-image-component";
import successIcon from '../src/img/success.svg';
import './OrderSuccess.css';

const OrderSuccess = () => {
    return (
        <div className="order-success-container">
            <NavBar parallax={false}/>
            <div className="order-success-content">
                <div className="order-success-icon">
                    <LazyLoadImage
                        src={successIcon}
                        className="img-lazy"
                        alt="Success"
                        effect='blur' // opacity | black-and-white
                    />
                </div>
                <h2 className="order-success-heading">Order Placed Successfully!</h2>
                <p className="order-success-text">Your order has been successfully placed. Thank you for your
                    purchase!</p>
                <hr/>
                <p className="order-success-text">We will send you a confirmation email with the order details.</p>
            </div>
            <Footer/>
        </div>
    );
};

export default OrderSuccess;
