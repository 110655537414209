import React from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import logo from './../../src/img/logo.png';
import './NavBar.css';

const NavBar = ({parallax = true}) => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const navbarHeight = document.querySelector('.navbar').offsetHeight;
            const paddingTop = 10; // Valor del padding-top del contenedor, ajusta según tu diseño
            const offset = -(navbarHeight + paddingTop);
            window.scrollTo({
                top: element.offsetTop + offset,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Navbar bg="white" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="#">
                    <LazyLoadImage
                        src={logo}
                        className="img-lazy d-inline-block align-top" height={30}
                        effect='blur' // opacity | black-and-white
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav"/>
                <Navbar.Collapse id="navbar-nav">
                    {parallax === true ? (
                        <Nav className="navbar-links ml-auto">
                            <Nav.Link onClick={() => scrollToSection('home-section')}>Home</Nav.Link>
                            <Nav.Link onClick={() => scrollToSection('features-section')}>Features</Nav.Link>
                            <Nav.Link onClick={() => scrollToSection('pricing-section')}>Pricing</Nav.Link>
                            <Nav.Link onClick={() => scrollToSection('contact-section')}>Contact</Nav.Link>
                        </Nav>
                    ): (
                        <Nav className="navbar-links ml-auto">
                            <Nav.Link href="/#home-section">Home</Nav.Link>
                            <Nav.Link href="/#features-section">Features</Nav.Link>
                            <Nav.Link href="/#pricing-section">Pricing</Nav.Link>
                            <Nav.Link href="/#contact-section">Contact</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
