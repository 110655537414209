import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import './css/Newsletter.css';
import icon_check_list from './../../src/img/icon-check-list.svg';

const Newsletter = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
        setLoading(true);

        try {
            const response = await fetch('https://sysneting.us17.list-manage.com/subscribe/post?u=58d4b979a45656388ec334b37&id=426c365231', {
                method: 'POST',
                body: new URLSearchParams(data),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (response.ok) {
                setSubmitted(true);
                reset();
            } else {
                throw new Error('Failed to subscribe');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="subscription bg-white">
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className="subscription-wrapper">
                            <div className="d-flex subscription-content justify-content-between align-items-center flex-column flex-md-row text-center text-md-left">
                                <div className="flex-fill">
                                    <h3 className="flex-fill">Subscribe to get first-hand <br /> information about our offers!</h3>
                                    <div>
                                        <img src={icon_check_list} alt="" />
                                        <span>Join the users in our newsletter!</span>
                                    </div>
                                </div>

                                <div className="form-container flex-fill">
                                    {!submitted ? (
                                        <Form className="row" onSubmit={handleSubmit(onSubmit)}>
                                            <Col lg={12} className="my-md-2 my-2">
                                                <div className="input-group">
                                                    <Form.Control
                                                        type="email"
                                                        className={`form-control px-4 border-0 w-100 text-center text-md-left ${errors.EMAIL && 'is-invalid'}`}
                                                        placeholder="Your Email"
                                                        {...register('EMAIL', {
                                                            required: 'Email is required',
                                                            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address' },
                                                        })}
                                                        disabled={loading}
                                                    />
                                                    <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                                                        <input type="text" name="b_58d4b979a45656388ec334b37_426c365231" tabIndex="-1" defaultValue="" />
                                                    </div>
                                                    {loading ? (
                                                        <Button className="btn btn-primary btn-lg border-0 rounded-0" disabled>
                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Button>
                                                    ) : (
                                                        <Button type="submit" className="btn btn-primary btn-lg border-0 rounded-0">Subscribe Now</Button>
                                                    )}
                                                </div>
                                                {errors.EMAIL && <p className="invalid-feedback">{errors.EMAIL.message}</p>}
                                            </Col>
                                        </Form>
                                    ) : (
                                        <div className="submission-message">
                                            <p>You've subscribed successfully!</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Newsletter;
