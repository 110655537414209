import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import './css/Contact.css';
import contact_img from './../../src/img/contact.svg';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validar los campos requeridos
        if (!name || !email || !message) {
            setError('Please fill in all required fields.');
            return;
        }

        const formID = '231469123360653'; // ID del formulario
        const formData = {
            q1_name: name,
            q3_email: email,
            q10_company: company,
            'q11_phoneNumber[full]': phone,
            q5_message: message,
            formID,
            website: '', // Campo oculto
        };

        fetch('https://submit.jotform.com/submit/231469123360653', {
            method: 'POST',
            body: new URLSearchParams(formData),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => {
                if (response.ok) {
                    setSuccess(true);
                    setError('');
                    setName('');
                    setEmail('');
                    setPhone('');
                    setCompany('');
                    setMessage('');
                } else {
                    throw new Error('Error al enviar el formulario');
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div id="contact-section">
            <Container>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                <h5 className="section-subtitle">CONTACT US</h5>
                                <h1 className="section-title">Get in touch today</h1>
                                <p className="contact-text">
                                    Sometimes it is difficult to condense in a few words all the information we would like to provide,
                                    so do not hesitate to ask us any questions you may have.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <img src={contact_img} alt="Contact" className="contact-image" />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Form className="contact-form" onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12}>
                                    <p className="contact-text">
                                        If you didn't find the server you need, don't worry, we'll send you the specifications you need to
                                        send you a quote.
                                    </p>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Name *</Form.Label>
                                        <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required size="lg" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required size="lg" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control type="text" value={phone} onChange={(e) => setPhone(e.target.value)} size="lg" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="company">
                                        <Form.Label>Company</Form.Label>
                                        <Form.Control type="text" value={company} onChange={(e) => setCompany(e.target.value)} size="lg" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="message">
                                <Form.Label>Leave us a message *</Form.Label>
                                <Form.Control as="textarea" rows={5} value={message} onChange={(e) => setMessage(e.target.value)} required />
                            </Form.Group>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">Form submitted successfully!</Alert>}
                            <Row>
                                <Col md={12}>
                                    <Button variant="primary" type="submit" className="btn-contact-opersync">
                                        Send
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Contact;
