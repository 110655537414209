import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import './TopBar.css';

const TopBar = () => {
    const [open, setOpen] = useState(true);

    return (
        <div className="alert-container">
            <Collapse in={open}>
                <div>
                    <Alert variant="dark-opersync" onClose={() => setOpen(false)} dismissible>
                        <span className="message">
                            Get 20% OFF on your first order. Subscribe to our newsletter and get your coupon!
                        </span>
                    </Alert>
                </div>
            </Collapse>
        </div>
    );
};

export default TopBar;
