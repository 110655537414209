import React from 'react';
import {Col, Row} from 'react-bootstrap';

import CI_CD from "../Cards/CI_CD";
import VersionControl from "../Cards/VersionControl";
import MultipleUsers from "../Cards/MultipleUsers";
import Security from "../Cards/Security";
import './css/Features.css';

const Features = () => {
    return (
        <div id="features-section">
            <Row className="Features">
                <Col md={12} className="Features-Col-1">
                    <h1 className="heading-h1-size">
                        Features
                    </h1>
                    <p className="paragraph-default">
                        You need the best and we have the best for you to get the most out of your cloud server.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <CI_CD/>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <VersionControl/>
                </Col>
                <Col md={4}>
                    <MultipleUsers/>
                </Col>
                <Col md={4}>
                    <Security/>
                </Col>
            </Row>
        </div>
    );
};

export default Features
