import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";

import { hotjar } from 'react-hotjar';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const ga4Id = process.env.REACT_APP_GA4_ID;
const hotjarId = process.env.REACT_APP_HOTJAR_ID;

// Configuración de Google Analytics
ReactGA.initialize(ga4Id);

hotjar.initialize(hotjarId, 6);

// Check if Hotjar has been initialized before calling its methods
if (hotjar.initialized()) {
    hotjar.identify('USER_ID', { userProperty: 'value' });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
