import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const apiUrl = process.env.REACT_APP_API_OPERSYNC_URL;
const stripepublickey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(stripepublickey);

const CheckoutButton = ({ email, qty, product_id, suscription }) => {
    const [loading, setLoading] = useState(false);

    const handleCheckout = async () => {
        setLoading(true);

        try {
            const stripe = await stripePromise;
            let pathUrl = '';
            if (suscription > 0) {
                pathUrl = '/api/V1/out-web-checkout-suscription';
            } else {
                pathUrl = '/api/V1/out-web-checkout';
            }
            const response = await fetch(apiUrl + pathUrl, {
                method: 'POST',
                body: JSON.stringify({ email, qty, product_id }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const session = await response.json();

            await stripe.redirectToCheckout({ sessionId: session.id });
        } catch (error) {
            console.error('Error al redirigir a la página de pago:', error);
            setLoading(false);
        }
    };

    return (
        <button className="btn btn-primary" onClick={handleCheckout} disabled={loading}>
            {loading ? 'Loading...' : 'Pre Order'}
        </button>
    );
};

export default CheckoutButton;
